<template>
  <v-app>
    <v-container>
      <h1>{{lessonCourseOneToOne.title}}</h1>
      <div>
        <h4>1. Nội dung bài học</h4>
        <v-row>
          <v-col cols="12">
            <CkContent
                validateName="question"
                :content="lessonCourseOneToOne.script_content"
                @getData="(value) => lessonCourseOneToOne.script_content = value"
            ></CkContent>
          </v-col>
        </v-row>

        <h4>2. Ghi chú</h4>
        <v-row>
          <v-col cols="12">
            <CkContent
                validateName="question"
                :content="lessonCourseOneToOne.note"
                @getData="(value) => lessonCourseOneToOne.note = value"
            ></CkContent>
          </v-col>
        </v-row>
        <v-row class="mt-4 align-center justify-center">
          <v-col cols="5">
            <hnr-button
                :text="'Lưu'"
                color="#000000"
                white-text="true"
                width = "200"
                rounded
                @hnrClickEvent="saveLesson()"
            ></hnr-button>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </v-app>
</template>
<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  GET_DETAIL_LESSON,
  UPDATE_LESSON
} from "@/store/lessonCourseOneToOne.module";
import {mapGetters} from "vuex";
export default {
  data() {
    return {

    };
  },
  async mounted() {
    await this.$store.dispatch(GET_DETAIL_LESSON,{lesson_slug : this.$route.query.lesson_slug})
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: this.lessonCourseOneToOne.title }]);
    // set the tab from previousmounted() {
  },
  components:{
    CkContent : () => import("@/components/ck-content"),
    HnrButton : ()=> import("@/components/elements/hnr-button")
  },
  computed: {
    ...mapGetters({
      lessonCourseOneToOne: "lessonCourseOneToOne",
    })
  },

  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    saveLesson(){
      if (!this.lessonCourseOneToOne.script_content) {
        this.$toasted.error('Bạn chưa nhập nội dung bài học !!', {
          position: "top-right",
          duration: 3000
        })
        return false
      }
      this.$store
          .dispatch(UPDATE_LESSON, this.lessonCourseOneToOne).then((data) => {
        if (data.status) {
          this.$toasted.success('Chỉnh sửa bài học thành công', {
            position: "top-right",
            duration: 3000
          })
        }
        else {
          this.$toasted.error('Chỉnh sửa bài học thất bại', {
            position: "top-right",
            duration: 3000
          })
        }
      });
    }
  },
};
</script>
